module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en","fr","ja","es"],"defaultLanguage":"en","siteUrl":"https://medbed.com","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[],"react":{"useSuspense":false,"wait":false},"initImmediate":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"90.10. MedBed","short_name":"90.10. MedBed","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/assets/images/favicon-180x180.jpeg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f2fb2df255397e2ca8ed7dab551f4ce"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
